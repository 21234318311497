<template>
  <p-form-element
    type="dropdown"
    :size="size"
    :disabled="disabled"
    :label="label"
    :helpText="helpText"
    :helpInTooltip="helpInTooltip"
    :error="error"
    :slot="$attrs.slot"
  >
    <p-font-selector
      :model-value="modelValue"
      :disabled.prop="disabled"
      :error.prop="!!error"
      :placeholder="placeholder"
      @select="onSelect"
      @deselect="onDeselect"
      @clear="onClear"
    />
  </p-form-element>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { PropType } from 'vue';

@Component({
  components: {},
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: String, required: false, default: null }) public readonly modelValue!: string | null;
  @Prop({
    type: String as PropType<'extra-small' | 'small' | 'medium' | 'large' | 'extra-large'>,
    required: false,
    default: 'extra-large',
    validator: (value: string) => ['extra-small', 'small', 'medium', 'large', 'extra-large'].includes(value)
  })
  public readonly size?: string;

  @Prop({ type: String, required: false, default: undefined }) public readonly label?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly helpText?: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly helpInTooltip?: boolean;
  @Prop({ type: String, required: false, default: undefined }) public readonly placeholder?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly error?: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly disabled!: boolean;

  public onSelect(evt: CustomEvent<string[]>): void {
    this.$emit('update:modelValue', evt.detail[0]);
  }

  public onDeselect(_evt: CustomEvent<string[]>): void {
    this.$emit('update:modelValue', null);
  }

  public onClear(): void {
    this.$emit('update:modelValue', null);
  }
}
</script>
