import Vue from 'vue';

// Import UI components
import Video from '@/components/ui/Video.vue';
import Link from '@/components/ui/Link.vue';
import Page from '@/components/ui/Page.vue';
import Dictionary from '@/components/ui/Dictionary.vue';
import SkeletonLoader from '@/components/ui/SkeletonLoader.vue';
import ChartArea from '@/components/ui/chart/Area.vue';
import ChartDoughnut from '@/components/ui/chart/Doughnut.vue';
import ChartBar from '@/components/ui/chart/Bar.vue';
import ProgressStep from '@/components/ui/ProgressStep.vue';
import ModalMedia from '@/components/ui/modal/media/index.vue';
import Transition from '@/components/ui/Transition.vue';
import Accordion from '@/components/ui/Accordion.vue';
import Divider from '@/components/ui/Divider.vue';
import ContextMenu from '@/components/ui/ContextMenu.vue';
import SvgObject from '@/components/ui/SvgObject.vue';
import Toast from '@/components/ui/Toast.vue';
import Indicator from '@/components/ui/Indicator.vue';

import SettingsNavigation from '@/components/ui/form/SettingsNavigation.vue';
import Checkbox from '@/components/ui/form/Checkbox.vue';
import Checkboxes from '@/components/ui/form/Checkboxes.vue';
import Search from '@/components/ui/form/Search.vue';
import CodeEditor from '@/components/ui/form/CodeEditor.vue';
import TextEditor from '@/components/ui/form/texteditor/TextEditor.vue';
// import TextEditorOld from '@/components/ui/form/OldTextEditor.vue';
import TimePicker from '@/components/ui/form/TimePicker.vue';
import ColorPicker from '@/components/ui/form/ColorPicker.vue';
import MediaSelector from '@/components/ui/form/MediaSelector.vue';
import DatePicker from '@/components/ui/form/DatePicker.vue';
import DateTimePicker from '@/components/ui/form/DateTimePicker.vue';
import Table from '@/components/ui/Table.vue';
import TableClient from '@/components/ui/Table-client.vue';
import RangeSlider from '@/components/ui/form/RangeSlider.vue';
import TagsInput from '@/components/ui/form/TagsInput.vue';
import Positioner from '@/components/ui/form/Positioner.vue';

import FormCard from '@/components/ui/form/FormCard.vue';
import FormTextarea from '@/components/ui/form/FormTextarea.vue';
import FormRadio from '@/components/ui/form/FormRadio.vue';
import FormCheckbox from '@/components/ui/form/FormCheckbox.vue';
import FormSwitch from '@/components/ui/form/FormSwitch.vue';
import FormTabs from '@/components/ui/form/FormTabs.vue';
import FormSize from '@/components/ui/form/FormSize.vue';
import FormColorPicker from '@/components/ui/form/FormColorPicker.vue';
import FormCodeEditor from '@/components/ui/form/FormCodeEditor.vue';
import FormTextEditor from '@/components/ui/form/FormTextEditor.vue';
import FormTimePicker from '@/components/ui/form/FormTimePicker.vue';
import FormInput from './components/ui/form/FormInput.vue';
import FormCheckboxes from '@/components/ui/form/FormCheckboxes.vue';
import FormSearch from '@/components/ui/form/FormSearch.vue';
import FormElement from '@/components/ui/form/FormElement.vue';
import FormDatePicker from '@/components/ui/form/FormDatePicker.vue';
import FormDateTimePicker from '@/components/ui/form/FormDateTimePicker.vue';
import FormRangeSlider from '@/components/ui/form/FormRangeSlider.vue';
import FormMediaSelector from '@/components/ui/form/FormMediaSelector.vue';
import FormTagsInput from '@/components/ui/form/FormTagsInput.vue';
import FormPositioner from '@/components/ui/form/FormPositioner.vue';

import MainHeader from '@/components/ui/custom/menu/MainHeader.vue';
import NavTabs from '@/components/ui/custom/nav-tabs/index.vue';
import NavTabsCampaign from '@/components/ui/custom/nav-tabs/Campaign.vue';
import CustomStatBoxInverted from '@/components/ui/custom/StatBoxInverted.vue';
import CustomStatBox from '@/components/ui/custom/StatBox.vue';
import CustomBandwidthStats from '@/components/ui/custom/BandwidthStats.vue';
import CustomCampaignTopbar from '@/components/ui/custom/campaign/Topbar.vue';
import FormSelect from '@/components/ui/form/FormSelect.vue';
import FormIconSelector from '@/components/ui/form/FormIconSelector.vue';
import IconSelector from '@/components/ui/form/IconSelector.vue';
import CustomSlideshow from '@/components/ui/custom/Slideshow.vue';
import CustomFreemiumTopbar from '@/components/ui/custom/FreemiumTopbar.vue';

import AIAssistant from '@/components/ui/form/AiAssistant.vue';
import ModalAiAssistant from '@/components/ui/modal/ai/index.vue';
import Slideshow from '@/components/ui/Slideshow.vue';
import FormFontSelector from '@/components/ui/form/FormFontSelector.vue';

Vue.component('p-video', Video);
Vue.component('p-link', Link);
Vue.component('p-settings-navigation', SettingsNavigation);
Vue.component('p-page', Page);
Vue.component('p-dictionary', Dictionary);
Vue.component('p-skeleton-loader', SkeletonLoader);
Vue.component('p-chart-area', ChartArea);
Vue.component('p-chart-bar', ChartBar);
Vue.component('p-chart-doughnut', ChartDoughnut);
Vue.component('p-modal-media', ModalMedia);
Vue.component('p-modal-ai-assistant', ModalAiAssistant);
Vue.component('p-ai-assistant', AIAssistant);
Vue.component('p-transition', Transition);
Vue.component('p-accordion', Accordion);
Vue.component('p-divider', Divider);
Vue.component('p-context-menu', ContextMenu);
Vue.component('p-svg-object', SvgObject);
Vue.component('p-toast', Toast);
Vue.component('p-indicator', Indicator);

Vue.component('p-checkbox', Checkbox);
Vue.component('p-checkboxes', Checkboxes);
Vue.component('p-search', Search);
Vue.component('p-code-editor', CodeEditor);
Vue.component('p-text-editor', TextEditor);
// Vue.component('p-text-editor-old', TextEditorOld); // saving for quick testing
Vue.component('p-time-picker', TimePicker);
Vue.component('p-color-picker', ColorPicker);
Vue.component('p-media-selector', MediaSelector);
Vue.component('p-table', Table);
Vue.component('p-table-client', TableClient);
Vue.component('p-date-picker', DatePicker);
Vue.component('p-date-time-picker', DateTimePicker);
Vue.component('p-progress-step', ProgressStep);
Vue.component('p-range-slider', RangeSlider);
Vue.component('p-tags-input', TagsInput);
Vue.component('p-icon-selector', IconSelector);
Vue.component('p-positioner', Positioner);
Vue.component('p-slide-show', Slideshow);

Vue.component('p-form-element', FormElement);
Vue.component('p-form-input', FormInput);
Vue.component('p-form-card', FormCard);
Vue.component('p-form-textarea', FormTextarea);
Vue.component('p-form-radio', FormRadio);
Vue.component('p-form-checkbox', FormCheckbox);
Vue.component('p-form-switch', FormSwitch);
Vue.component('p-form-tabs', FormTabs);
Vue.component('p-form-size', FormSize);
Vue.component('p-form-color-picker', FormColorPicker);
Vue.component('p-form-code-editor', FormCodeEditor);
Vue.component('p-form-text-editor', FormTextEditor);
Vue.component('p-form-select', FormSelect);
Vue.component('p-form-font-selector', FormFontSelector);
Vue.component('p-form-time-picker', FormTimePicker);
Vue.component('p-form-checkboxes', FormCheckboxes);
Vue.component('p-form-search', FormSearch);
Vue.component('p-form-date-picker', FormDatePicker);
Vue.component('p-form-date-time-picker', FormDateTimePicker);
Vue.component('p-form-range-slider', FormRangeSlider);
Vue.component('p-form-media-selector', FormMediaSelector);
Vue.component('p-form-tags-input', FormTagsInput);
Vue.component('p-form-icon-selector', FormIconSelector);
Vue.component('p-form-positioner', FormPositioner);

Vue.component('p-main-header', MainHeader);
Vue.component('p-nav-tabs', NavTabs);
Vue.component('p-nav-tabs-campaign', NavTabsCampaign);
Vue.component('p-custom-stat-box-inverted', CustomStatBoxInverted);
Vue.component('p-custom-stat-box', CustomStatBox);
Vue.component('p-custom-bandwidth-stats', CustomBandwidthStats);
Vue.component('p-custom-campaign-topbar', CustomCampaignTopbar);
Vue.component('p-custom-slide-show', CustomSlideshow);
Vue.component('p-custom-freemium-topbar', CustomFreemiumTopbar);
