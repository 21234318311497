<template>
  <div class="debugger-element">
    <p-modal-plain
      :show.prop="showDebugger"
      headline="Request Log"
      position="top"
      @close-request="showDebugger = false"
    >
      <p-container>
        <p-table-client
          pagination
          :header="[
            { id: 'method', label: 'Method', width: '100px', verticalAlign: 'middle', hasPadding: true },
            { id: 'url', label: 'URL', verticalAlign: 'middle' },
            { id: 'status', label: 'Status code', width: '120px', verticalAlign: 'middle', hasPadding: true },
            { id: 'actions', label: '', width: '50px', verticalAlign: 'middle' }
          ]"
          :body="entries"
        >
          <template #bodyCell="{ row, header }">
            <template v-if="header.id === 'actions'">
              <p-tooltip text="View" position="bottom">
                <p-button @click="viewLog(row)" color-type="tertiary" icon="eye" size="medium" />
              </p-tooltip>
            </template>

            <template v-else-if="header.id === 'method'">
              <p-badge :text="row.request.method" />
            </template>

            <template v-else-if="header.id === 'url'">
              <p-paragraph>
                {{ row.request.url }}
              </p-paragraph>
            </template>

            <template v-else-if="header.id === 'status'">
              <p-badge
                v-if="row.response.status >= 200 && row.response.status <= 299"
                type="positive"
                :text="row.response.status"
              />

              <p-badge
                v-else-if="row.response.status >= 500 && row.response.status <= 599"
                type="negative"
                :text="row.response.status"
              />

              <p-badge
                v-else-if="row.response.status >= 400 && row.response.status <= 499"
                type="warning"
                :text="row.response.status"
              />

              <p-badge v-else :text="row.response.status ?? 'N/A'" />
            </template>

            <template v-else>
              <p-paragraph>
                {{ row[header.id] }}
              </p-paragraph>
            </template>
          </template>
        </p-table-client>
      </p-container>
    </p-modal-plain>

    <p-modal-plain
      :show.prop="true"
      :headline="`Log ${selectedLog.request.url}`"
      position="top"
      v-if="selectedLog"
      @close-request="selectedLog = null"
    >
      <p-container>
        <p-headline size="3">Request</p-headline>

        <div class="debugger-element__log-details">
          <div class="debugger-element__log-detail">
            <span class="debugger-element__log-key">Method:</span> <p-badge :text="selectedLog.request.method" />
          </div>

          <div class="debugger-element__log-detail">
            <span class="debugger-element__log-key">URL:</span> {{ selectedLog.request.url }}
          </div>

          <div class="debugger-element__log-detail">
            <span class="debugger-element__log-key">Headers:</span><br /><br />
            <pre>{{ JSON.stringify(selectedLog.request.headers, null, 2) }}</pre>
          </div>

          <div v-if="selectedLog.request.method !== 'GET'" class="debugger-element__log-detail">
            <span class="debugger-element__log-key">Body:</span><br /><br />
            <pre>{{ JSON.stringify(selectedLog.request.body, null, 2) }}</pre>
          </div>
        </div>
      </p-container>

      <p-container>
        <p-headline size="3">Response</p-headline>

        <div class="debugger-element__log-details">
          <div class="debugger-element__log-detail">
            <span class="debugger-element__log-key">Status:</span>

            <p-badge
              v-if="selectedLog.response.status >= 200 && selectedLog.response.status <= 299"
              type="positive"
              :text="selectedLog.response.status"
            />

            <p-badge
              v-else-if="selectedLog.response.status >= 500 && selectedLog.response.status <= 599"
              type="negative"
              :text="selectedLog.response.status"
            />

            <p-badge
              v-else-if="selectedLog.response.status >= 400 && selectedLog.response.status <= 499"
              type="warning"
              :text="selectedLog.response.status"
            />

            <p-badge v-else :text="selectedLog.response.status ?? 'N/A'" />
          </div>

          <div class="debugger-element__log-detail">
            <span class="debugger-element__log-key">Headers:</span><br /><br />
            <pre>{{ JSON.stringify(selectedLog.response.headers, null, 2) }}</pre>
          </div>

          <div class="debugger-element__log-detail">
            <span class="debugger-element__log-key">Body:</span><br /><br />
            <pre>{{
              JSON.stringify(
                selectedLog.response.body &&
                  (selectedLog.response.body[0] === '{' || selectedLog.response.body[0] === '[')
                  ? JSON.parse(selectedLog.response.body)
                  : selectedLog.response.body,
                null,
                2
              )
            }}</pre>
          </div>
        </div>
      </p-container>
    </p-modal-plain>

    <div class="debugger-element__button" v-if="entries && entries.length > 0">
      <p-button size="small" type="cta" color-type="primary" @click="showDebugger = true" v-device-desktop
        >Request log</p-button
      >
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { PropType } from 'vue';
import { RequestLog } from '@/interfaces/template';

@Component({
  name: 'request-log'
})
export default class extends Vue {
  @Prop({ type: Array as PropType<RequestLog[]> }) public readonly entries!: RequestLog[];

  public showDebugger = false;
  public selectedLog: RequestLog | null = null;

  public viewLog(log: RequestLog) {
    this.selectedLog = log;
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/mixins/typography';
@import '../../scss/mixins/devices';

.debugger-element {
  pre {
    background-color: var(--color-base-100);
    color: var(--color-base-600);
    page-break-inside: avoid;
    font-family: monospace;
    font-size: 15px;
    line-height: 1.6;
    max-width: 100%;
    overflow: auto;
    display: block;
    padding: 0.5em 1em;
    border-radius: 4px;
    width: 100%;
    white-space: pre-wrap;
  }

  &__button {
    position: fixed;
    left: 5px;
    bottom: 120px;
  }

  &__value--old {
    border-left: 3px solid var(--color-border-negative); /* Red for old values */
  }

  &__value--new {
    border-left: 3px solid var(--color-border-positive); /* Green for new values */
  }

  &__log-details {
    margin-bottom: 20px;
    width: 100%;
  }

  &__log-detail {
    margin: 10px 0;
    width: 100%;
  }

  &__log-key {
    font-weight: bold;
  }
}
</style>
