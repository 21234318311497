<template>
  <p-form-font-selector
    :label="element.properties.label"
    :help-text="element.properties.help"
    :help-in-tooltip="element.properties.helpInTooltip"
    :disabled="element.properties.disabled"
    :error="element.properties.errors.join(', ')"
    :size="element.properties.size"
    :placeholder="element.properties.placeholder"
    v-model="element.properties.value"
    @update:modelValue="onChange()"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IElementFontSelector } from '@/interfaces/element';
import { Trigger } from '@/Trigger';
import { Iframe } from '@/iframe';

@Component({
  components: {},
  name: 'layout-element-font-selector'
})
export default class extends Vue {
  @Prop() public element!: IElementFontSelector;

  @Watch('element.properties.value')
  onFastValueChange() {
    if (this.element.properties.name) {
      Iframe.valueChange(this.element.properties.name, this.element.properties.value);
    }
  }

  onChange() {
    this.$el.dispatchEvent(
      new CustomEvent('BLUEPRINT_INTERACT', {
        bubbles: true,
        composed: true
      })
    );

    if (this.element.properties.trigger && this.element.properties.trigger.type) {
      Trigger.handle(this.element.properties.trigger, this.$el, this.element.properties.name);
    }
  }
}
</script>
