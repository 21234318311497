<template>
  <p-message
    :type="element.properties.type"
    :display-type="element.properties.display"
    :headline="element.properties.title"
    :description="element.properties.description"
    :show-icon.prop="element.properties.icon"
  >
    <template v-for="(child, childIndex) in children">
      <layout-element :element="child" :key="child.key" />
      <template v-if="childIndex !== children.length - 1 && !hasButton && element.properties.display === 'inline'">
      </template>
    </template>
  </p-message>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IElementMessage } from '@/interfaces/element';
import { PropType } from 'vue';
import { getElementsForRender } from '@/utility';

@Component({
  name: 'layout-element-message'
})
export default class extends Vue {
  @Prop({ type: Object as PropType<IElementMessage>, required: true }) public readonly element!: IElementMessage;

  public get children() {
    return getElementsForRender(this.element.children ?? []);
  }

  public get hasButton(): boolean {
    return this.children.some((child) => child.type === 'button');
  }
}
</script>
