<template>
  <p-container gap-size="extra-large">
    <div v-if="hasTopbar" class="topbar">
      <layout-element :element="template.params.topbar" />
    </div>

    <p-page :top-spacing="!hasTopbar">
      <p-nav-tabs
        v-if="template.params?.headline || tabs.length > 0"
        :headline="template.params?.headline"
        :subheadline="template.params?.subheadline"
        :build-campaign-link="buildYourCampaignTab?.url"
        :fast-track-link="fastTrackTab?.url"
        :items="tabs"
        :sub-items="subTabs"
      />

      <p-container>
        <slot />
      </p-container>
    </p-page>

    <audit-log :entries="template.params?.auditLog" />
    <request-log :entries="template.params?.requests" />
  </p-container>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ILayoutTemplate } from '@/interfaces/template';
import { IMenuItem } from '@/interfaces/menu';
import { TabsNavItem } from '@/components/ui/custom/nav-tabs/types';
import AuditLog from '@/components/debug/AuditLog.vue';
import RequestLog from '@/components/debug/RequestLog.vue';

@Component({
  name: 'template-default',
  components: { AuditLog, RequestLog }
})
export default class TemplateDefault extends Vue {
  @Prop() public readonly template!: ILayoutTemplate;

  public get menu(): IMenuItem[] {
    return this.template.params?.menu || [];
  }

  public get templateComputedTabs(): IMenuItem[] {
    let furthest: IMenuItem | null = null;

    if (this.menu && this.menu.length > 0) {
      this.menu.forEach((item) => {
        if (typeof item.data !== 'undefined' && item.data.selected) {
          furthest = this.findTabsRecursively(item);
        }
      });
    }

    return (
      furthest !== null && typeof (furthest as IMenuItem).children !== 'undefined'
        ? ((furthest as IMenuItem).children as IMenuItem[])
        : []
    ).filter((child) => {
      return !(typeof child.data !== 'undefined' && typeof child.data.hide !== 'undefined' && child.data.hide === true);
    });
  }

  public get buildYourCampaignTab(): IMenuItem | undefined {
    const tab = this.templateComputedTabs.find((item) => item.title === 'Build your campaign');

    if (tab) {
      return tab;
    }

    return undefined;
  }

  public get fastTrackTab(): IMenuItem | undefined {
    const tab = this.templateComputedTabs.find((item) => item.title === 'Fast track');

    if (tab) {
      return tab;
    }

    return undefined;
  }

  public get templateTabs(): IMenuItem[] {
    return this.templateComputedTabs.filter((child) => {
      return child.title !== 'Build your campaign' && child.title !== 'Fast track';
    });
  }

  public get templateSubTabs(): IMenuItem[] {
    const tabs = this.templateTabs;
    let children: IMenuItem[] = [];

    tabs.forEach((menuItem) => {
      if (
        typeof menuItem.data !== 'undefined' &&
        menuItem.data.selected &&
        typeof menuItem.children !== 'undefined' &&
        !menuItem.data.foldout
      ) {
        children = menuItem.children;
      }
    });

    return children.filter((child) => {
      return !(typeof child.data !== 'undefined' && typeof child.data.hide !== 'undefined' && child.data.hide === true);
    });
  }

  public get tabs(): TabsNavItem[] {
    return this.templateTabs.map((tab) => {
      return {
        text: tab.title,
        href: tab.url,
        active: tab.data?.selected === true,
        access: tab.hasAccess === true
      };
    });
  }

  public get subTabs(): TabsNavItem[] {
    return this.templateSubTabs.map((tab) => {
      return {
        text: tab.title,
        href: tab.url,
        active: tab.data?.selected === true,
        access: tab.hasAccess === true
      };
    });
  }

  public get hasTopbar() {
    return (
      this.template.params?.topbar &&
      this.template.params.topbar.children &&
      this.template.params.topbar.children.length > 0
    );
  }

  /**
   * Find tabs element recursively.
   */
  private findTabsRecursively(item: IMenuItem, parent?: IMenuItem): IMenuItem | null {
    if (typeof item.data !== 'undefined' && item.data.selected) {
      let childSelected: IMenuItem | undefined;

      if (typeof item.children !== 'undefined') {
        item.children.forEach((child) => {
          if (typeof child.data !== 'undefined' && child.data.selected) {
            childSelected = child;
          }
        });
      }

      // I know this method is awkward. But my brain can't comprehend another solution at the
      // moment to how weird the compiler is being right now..
      if (
        typeof childSelected !== 'undefined' &&
        childSelected !== null &&
        'children' in childSelected &&
        typeof (childSelected as IMenuItem).children !== 'undefined' &&
        ((childSelected as IMenuItem).children as IMenuItem[]).length > 0
      ) {
        return this.findTabsRecursively(childSelected as IMenuItem, item);
      } else if (typeof parent !== 'undefined' && typeof item.children !== 'undefined') {
        return typeof parent.data !== 'undefined' && parent.data.foldout ? item : parent;
      } else {
        return typeof item.data !== 'undefined' && item.data.foldout ? null : item;
      }
    }

    return item;
  }
}
</script>

<style lang="scss">
@import '../scss/mixins/devices';

.topbar {
  width: 100%;
  min-width: 1200px;
}

@include for-mobile-only {
  .topbar {
    min-width: 0;
    max-width: 100%;
  }
}

@media print {
  .topbar {
    display: none;
  }
}
</style>
